<template>
  <HomePageContents />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import HomePageContents from '@/components/HomePageContents.vue'

export default defineComponent({
  components: {
    HomePageContents,
  },
})
</script>

<style lang="scss">
@import '@/styles/styles.scss';
</style>
