
import { defineComponent, onMounted } from 'vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'

export default defineComponent({
  setup() {
    gsap.registerPlugin(ScrollTrigger, MotionPathPlugin)

    const startHeaderAnimation = () => {
      /** HEADER ANIMATION **/
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '#logoLarge',
          start: 'bottom top',
          toggleActions: 'play play reverse reverse',
        },
      })

      tl.addLabel('start')
      tl.set('#mainNav', { autoAlpha: 1 })
      tl.to('#mainNav', { duration: 0.3, y: 0, ease: 'power1.out' }, 'start')
    }

    onMounted(() => {
      startHeaderAnimation()
    })

    const currentYear = new Date().getFullYear()
    return {
      currentYear,
    }
  },
})
